<template>
  <Dialog dismissable-mask @hide="$emit('close')" :draggable="false" v-model:visible="showDialogLocal" header="Créer un avoir" :modal="true" class="p-fluid">
    <div class="mt-4">
      <div class="flex flex-row-reverse align-items-center gap-2 mb-2">
        <Label>Fusionner les lignes de l'avoir généré</Label>
        <Checkbox v-model="mergeLines" binary/>
      </div>
      <table>
        <tr class="custom-border-top-1 custom-border-x-1">
          <th>Choix</th>
          <th>Désignation</th>
          <th>Quantité</th>
          <th>Prix Unit.</th>
          <th>Montant</th>
        </tr>
        <tr class="custom-border-x-1 cursor-pointer" v-for="(service, indexS) in servicesToBill" :key="indexS">
          <td class="justify-content-center">
            <Checkbox v-if="service.service.avoir == null && service.service.price > 0" :binary="true" v-model="checkedServices[indexS]"/>
            <Button style="width: 50%; height: 10%" v-else label="Voir l'avoir" @click="goToAvoir(service)" class="p-button-rounded"></Button>
          </td>
          <td class="justify-content-start" @click="showPopupService(service)">{{service.label}}</td>
          <td class="justify-content-center" @click="showPopupService(service)">{{service.qty}}</td>
          <td class="justify-content-end" @click="showPopupService(service)">{{getEuroFormatFromEuro(service.PU, false)}}</td>
          <td class="justify-content-end" @click="showPopupService(service)">{{getEuroFormatFromEuro(service.tot, false)}}</td>
        </tr>
        <tr>
          <td class="td-border-top"></td>
          <td class="td-border-top"></td>
          <td class="td-border-top"></td>
          <td class="td-border-top"></td>
          <td class="td-border-top td-border-bottom"></td>
        </tr>
        <tr class="custom-border-right-1">
          <td class="td-no-border"></td>
          <td class="td-no-border"></td>
          <td class="td-no-border"></td>
          <td class="td-border-right justify-content-center">Total HT</td>
          <td class="justify-content-end">{{getEuroFormatFromEuro(totalHT, false)}}</td>
        </tr>
        <tr class="custom-border-right-1">
          <td class="td-no-border"></td>
          <td class="td-no-border"></td>
          <td class="td-no-border"></td>
          <td class="td-border-right-only justify-content-center"> TVA {{getPercentFormat(tauxTVA)}}</td>
          <td class="justify-content-end">{{getEuroFormatFromEuro(TVA, false)}}</td>
        </tr>
        <tr class="custom-border-right-1">
          <td class="td-no-border"></td>
          <td class="td-no-border"></td>
          <td class="td-no-border"></td>
          <td class="td-border-right-only justify-content-center font-bold"> TOTAL </td>
          <td class="custom-border-bottom-2 justify-content-end font-bold td-total">
                <span class="pr-1">
                  {{getEuroFormatFromEuro(totalTTC, true)}}
                </span>
          </td>
        </tr>
        <tr class="custom-border-right-1" v-if="currency?.code !== 'EUR'">
          <td class="td-no-border"></td>
          <td class="td-no-border"></td>
          <td class="td-no-border"></td>
          <td class="td-border-right-only justify-content-center font-bold"> TOTAL ({{currency.symbol}})</td>
          <td class="custom-border-bottom-2 justify-content-end font-bold td-total">
                <span class="pr-1">
                  {{getEuroFormatFromCurrency(totalTTC * this.conversion_rate, currency.code)}}
                </span>
          </td>
        </tr>
      </table>
    </div>
    <div class="flex justify-content-end mt-5">
      <Button style="width: 30%" v-if="servicesToBill.length > 0 && checkedServices.find((c, index) => c && servicesToBill[index].service.avoir == null) && totalTTC > 0" label="Créer un avoir" @click="creerAvoir" class="p-button-rounded ml-3"></Button>
    </div>
  </Dialog>
  <dialog-n-d-f-admin :key="keyDialog" :dialog="dialogNdf" :ndf="selectedNdf" @close="dialogNdf = false"></dialog-n-d-f-admin>

  <dialog-prestation v-if="dialogPrestation" :key="keyDialog" :dialog="dialogPrestation" :service="serviceEdit" @close="dialogPrestation = false"></dialog-prestation>

  <Dialog v-if="serviceDialog" :draggable="false" dismissable-mask v-model:visible="serviceDialog" :style="{width: '1000px'}" :modal="true" :show-header="true">
    <creation-service :service="serviceEdit"></creation-service>
  </Dialog>
</template>

<script>
import {getEuroFormatFromCurrency, getEuroFormatFromEuro, getPercentFormat} from "@/utils/Money";
import DialogNDFAdmin from "@/components/NDF/DialogNDFAdmin";
import DialogPrestation from "@/components/DialogPrestation";
import CreationService from "@/views/Pages/ADV/CreationService";

export default {
  name: "DialogCreerAvoir",
  emits: ["close", "init"],
  components: {CreationService, DialogPrestation, DialogNDFAdmin},
  props: ["servicesToBill", "dialog", "currency", "tauxTVA", "TVA", "totalTTC", "conversion_rate", "totalHT", "invoiceId"],
  data () {
    return {
      showDialogLocal: this.dialog,
      serviceEdit: null,
      selectedService: null,
      serviceDialog: false,
      selectedNdf: null,
      keyDialog: 0,
      dialogNdf: false,
      dialogPrestation: false,
      checkedServices: [],
      mergeLines: false
    }
  },
  mounted() {
    this.servicesToBill.forEach((s) => {
      this.checkedServices.push(s.service.avoir != null)
    })
  },
  methods: {
    async creerAvoir () {
      const servicesIds = this.servicesToBill
          .filter((s, index) => this.checkedServices[index] && s.service.avoir == null)
          .map(s => s.id)
      this.$store.state.misc.loading = true

      const avoir = await this.$store.dispatch('invoice/creerAvoir', {
        servicesIds,
        invoiceId: this.invoiceId,
        mergeLines: this.mergeLines
      })
      this.$toast.add({severity:'success', summary:'Succès', detail:'Avoir créé', life: 3000});
      this.$router.push({name: 'FactureId', params: { id: avoir.data.id }})
      this.$router.go(0)
      this.$store.state.misc.loading = false
      this.showDialogLocal = false
    },
    async goToAvoir (service) {
      this.$store.state.misc.loading = true
      this.$router.push({name: 'FactureId', params: { id: service.service.avoir }}).then(() => {
        this.$router.go(0)
      })
      // await this.$store.dispatch('invoice/getOneInvoice', { id: service.service.avoir })
      this.showDialogLocal = false
      // this.$emit('init')
      this.$store.state.misc.loading = false
    },
    showPopupService (service) {
      let s = this.servicesToBill.find((se) => se.id === service.id).service
      if (s.source.length > 0) {
        if (s.source[0].collection === 'ndf') {
          this.selectedNdf = s.source[0].item
          this.selectedNdf.date = new Date(this.selectedNdf.date)
          this.selectedNdf.depenses.map(dep => {
            if (!dep.processed) {
              dep.date = new Date(dep.date)
              dep.ttc /= 100
              dep.ht /= 100
              dep.tva /= 100
            }
            dep.processed = true
          })
          this.keyDialog++
          this.dialogNdf = true
        } else if (s.source[0].collection === 'DeclarationFacturation') {
          this.serviceEdit = s
          this.keyDialog++
          this.dialogPrestation = true
        } else {
          this.openServiceDialog(s)
        }
      } else {
        this.openServiceDialog(s)
      }
    },
    openServiceDialog (service) {
      this.serviceEdit = service
      this.serviceDialog = true
    },
    closeServiceDialog () {
      this.serviceEdit = null
      this.serviceDialog = false
    },
    getEuroFormatFromEuro (price, withUnit) {
      return getEuroFormatFromEuro(price, withUnit)
    },
    getEuroFormatFromCurrency (price, currency) {
      return getEuroFormatFromCurrency(price, currency)
    },
    getPercentFormat (value) {
      return getPercentFormat(value)
    },
  }
}
</script>

<style scoped>
th, td {
  border: solid black 1px;
}
.custom-border-top-1 {
  border-top: solid black 1px;
}
.custom-border-right-1 {
  border-right: solid black 1px;
  border-left: solid transparent 1px;
}
.custom-border-x-1 {
  border-right: solid black 1px;
  border-left: solid black 1px;
}
.custom-border-bottom-2 {
  border-bottom: solid black 2px;
}
.td-border-top {
  border-top: solid black 1px !important;
  border-left: solid transparent 1px;
  border-right: solid transparent 1px;
  border-bottom: solid transparent 1px;
}
.td-border-right {
  border-right: solid black 1px !important;
  border-left: solid transparent 1px;
  border-bottom: solid transparent 1px;
  border-top: solid transparent 1px;
}
.td-border-bottom {
  border-bottom: solid black 1px !important;
  border-left: solid transparent 1px;
  border-right: solid transparent 1px;
  border-top: solid transparent 1px;
}
.td-border-right-only {
  border-right: solid black 1px !important;
  border-left: solid transparent 1px;
  border-bottom: solid transparent 1px;
  border-top: solid transparent 1px;
}
.td-no-border {
  border-color: transparent;
}
table {
  border-collapse: collapse;
  width: 42vw;
}
tr {
  display: flex;

}
th {
  background-color: #DDDDDD;
  font-weight: bold;
  font-size: medium;
  color: black;
  height: 100%;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.td-total {
  background-color: #DDDDDD;
  font-size: 14px;
}
td {
  font-size: 13px;
  color: black;
  height: 100%;
  align-items: center;
  width: 100%;
  display: flex;
}
tr {
  height: 1.85vw;
}
</style>
