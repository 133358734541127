<template>
  <div class="content-section implementation">
    <div class="card">
      <div class="flex flex-column gap-2">
        <div class="flex flex-row p-fluid align-items-center justify-content-between">
          <label class="mb-0">À : </label>
          <AutoComplete
            v-model="contacts"
            :suggestions="filteredContacts"
            dropdown
            force-selection
            multiple
            field="email"
            placeholder="Rechercher ou selectionner un contact existant"
            class="w-10"
            @complete="searchContacts($event)"
            @dropdown-click="searchContacts($event, true)">
              <template #item="slotProps">
                <div class="flex flex-column">
                  <span>{{ `${slotProps.item.first_name} ${slotProps.item.last_name}, ${slotProps.item.email}` }}</span>
                  <Divider class="m-0"/>
                </div>
              </template>
          </AutoComplete>
          <Button icon="pi pi-plus" class="mr-2 p-button-outlined h-3rem" @click="addContact"/>
        </div>

        <div class="field">
          <label class="mb-0">Objet : </label>
          <InputText v-model="subject" placeholder="Objet" class="w-11"/>
        </div>
        <Editor v-model="message" editorStyle="height: 200px;"/>
      </div>
    </div>
    <div class="flex justify-content-end">
      <Button :label="$t('send')" class="p-button-rounded" @click="send" />
    </div>
  </div>
  <dialog-crud-contact
      :is-active="openCrudContact"
      :client-id="this.invoice.mission.clientContract.id"
      :contact-type="constants.CLIENT_TYPES.CONTACT"
      @closeDialog="resetDialog"
      @hide="resetDialog"
      @submitContact="setBillingContact"
  />
</template>

<script>
import Alert from "@/utils/Alert";
import {mapState} from "vuex";
import DialogCrudContact from "@/components/Client/DialogCrudContact.vue";

export default {
  name: "MailDialog",
  components: {DialogCrudContact},
  emits: ["close"],
  props: ['invoice'],
  data () {
    return {
      filteredContacts: [],
      contacts: null,
      subject: '',
      message: '',
      openCrudContact: false
    }
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings,
      constants: state => state.constants
    }),
  },
  mounted () {
    this.contacts = [this.invoice?.mission?.billingContact]
    this.message = this.settings.ADV.emailFacture.template
  },
  methods: {
    async send () {
      if (!this.contacts || this.contacts?.length === 0 || !this.subject || !this.message) {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez compléter tous les champs', life: 3000});
        return
      }

      if (this.contacts.some(contact => !(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,})+$/.test(contact.email)))) {
        Alert.errorMessage(this, 'invalidEmail')
        return
      }

      this.$store.state.misc.loading = true
      const data = {
        mailto: this.contacts.map(contact => contact.email),
        subject: this.subject,
        message: this.message,
        invoice: this.invoice.id,
        mission: {
          id: this.invoice.mission.id,
          billingContact: this.contacts[0] // consider that we take the first contact among all the one
        }
      }
      await this.$store.dispatch('invoice/sendMail', data)
      this.$toast.add({severity:'success', summary:'Succès', detail:'Mail envoyé', life: 3000});
      this.$store.state.misc.loading = false
      this.$emit('close')
    },
    addContact () {
      this.openCrudContact = true
    },
    resetDialog () {
      this.openCrudContact = false
    },
    setBillingContact (payload) {
      this.contacts.push(payload.clientData)
      this.resetDialog()
    },
    async searchContacts(event, force = false) {
      const data = {
        clientId: this.invoice.mission.clientContract.id
      }
      let filteredContacts = null
      if (force) {
        filteredContacts = await this.$store.dispatch('contact/getAllContacts', data)
      }
      if (!force && event.query.length > 2) {
        data.queryInput = event.query
        filteredContacts = await this.$store.dispatch('contact/getAllFilteredContacts', data)
      }
      if (filteredContacts !== null) {
        this.filteredContacts = filteredContacts.filter(contact => this.contacts.find(localContact => localContact.id === contact.id) === undefined)
      }
    }
  }
}
</script>

<style scoped>

</style>
