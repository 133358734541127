<template>
  <div class="scrollable">
    <div class="mx-1 mt-1 flex-row flex justify-content-between">
      <div style="width: 50%;">
        <div class="">
          <OuterCardTitle value="Edition de facture"></OuterCardTitle>
          <div class="surface-card p-4 shadow-9 surface-round p-fluid">
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12 md:col-12">
                <label for="indep">Intervention de </label>
                <InputText id="indep" disabled :value="independent ? independent.first_name + ' ' + independent.last_name : ''"/>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="mission">Mission</label>
                <InputText id="mission" disabled :value="mission ? mission.clientContract.name : ''"/>
              </div>
              <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
              <div class="field mb-4 col-12 md:col-12">
                <label for="invoiceDate">Date de facturation</label>
                <Calendar id="invoiceDate" :showIcon="true" v-model="invoiceDate" date-format="dd/mm/yy" :disabled="!editable && !invoiceEditingMode"></Calendar>
                <!--              {{mission.clientContract}}-->
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="bank">Banque</label>
                <Dropdown id="bank" v-model="bank" :options="accounts" option-label="libelle" option-group-label="label" option-group-children="items" :disabled="!editable && !invoiceEditingMode">
                  <template #footer>
                    <Button @click="dialogBankAccount = true" label="Ajouter un compte client" icon="pi pi-plus" class="p-button-outlined flex m-auto mt-1 mb-1"/>
                  </template>
                </Dropdown>
                <DialogCrudBankAccount :is-active="dialogBankAccount" @closeDialog="dialogBankAccount = false" @sendBankAccount="addBankAccount"></DialogCrudBankAccount>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="buyer-factor-code">Code acheteur du factor</label>
                <InputText id="buyer-factor-code" v-model="buyerFactorCode" :disabled="!editable && !invoiceEditingMode"/>
              </div>
              <div class="field mb-4 col-12 md:col-6" v-if="bank">
                <label for="iban1">IBAN</label>
                <InputText id="iban1" v-model="bank.iban" disabled/>
              </div>
              <div class="field mb-4 col-12 md:col-6" v-if="bank">
                <label for="bic">BIC</label>
                <InputText id="bic" v-model="bank.bic" disabled/>
              </div>
              <div class="field mb-4 col-12 md:col-12" v-if="bank">
                <label for="rib">RIB</label>
                <InputText id="rib" v-model="bank.rib" disabled/>
              </div>

              <div class="field mb-4 col-12 md:col-6">
                <label for="delay">Délai de règlement</label>
                <InputText id="delay" :value="mission?.jsonCost.settlementPeriod.unit === constants.SETTLEMENT_PERIOD_TYPES.ON_RECEPTION ? constants.SETTLEMENT_PERIOD_TYPES.ON_RECEPTION : mission?.jsonCost.settlementPeriod.value + ' ' + mission?.jsonCost.settlementPeriod.unit?.toLowerCase()" disabled/>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="settlementPeriod">Date d'échéance</label>
                <Calendar id="settlementPeriod" v-model="settlementPeriod" :showIcon="true" date-format="dd/mm/yy"
                          :disabled="!editable && !invoiceEditingMode" @update:modelValue="changeSettlementPeriod"/>
              </div>

              <div class="field mb-4 col-12 md:col-12">
                <label for="tvaIntra">N° TVA intracommunautaire</label>
                <InputText id="tvaIntra" v-model="tvaIntra" :disabled="!editable && !invoiceEditingMode"/>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="tva">TVA</label>
                <Dropdown id="tva" :show-clear="true" v-model="tauxTVA" :options="taxes" option-label="label" option-value="value" :disabled="!editable && !invoiceEditingMode"></Dropdown>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="currency">Devise</label>
                <Dropdown id="currency" v-model="currency" :options="currencyList"  option-label="name" :filter="true" :showClear="false" :disabled="!editable && !invoiceEditingMode">
                  <template #option="data">
                    <div class="flex justify-content-between">
                      <span class="capitalize">{{ data.option.name }}</span>
                      <span>{{ data.option.symbol }}</span>
                    </div>
                  </template>
                  <template #value="data">
                    <div class="flex justify-content-between">
                      <span class="capitalize">{{ data.value?.name }}</span>
                      <span>{{ data.value?.symbol }}</span>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div class="field mb-4 col-12 flex align-items-center">
                <Checkbox :binary="true" id="vatLiquidation" v-model="vatLiquidation" :disabled="!editable && !invoiceEditingMode" />
                <label class="ml-2 text-900" for="vatLiquidation">Liquidation de la TVA</label>
              </div>
              <div class="field mb-4 col-12 md:col-12" v-if="currency?.code !== 'EUR'">
                <label for="rate">Conversion</label>
                <InputNumber id="rate" v-model="conversion_rate" :min="0" :minFractionDigits="4"
                             :disabled="!editable && !invoiceEditingMode" highlightOnFocus/>
              </div>
              <div class="field mb-4 col-12 md:col-12" v-if="bank && bank.type === 'affacturage'">
                <label for="numAcheteur">Numéro d'acheteur (Factor)</label>
                <InputText id="numAcheteur" v-model="numAcheteur" :disabled="!editable && !invoiceEditingMode"/>
              </div>
              <div class="field mb-4 col-12 md:col-12">
                <label for="refCli">Référence client</label>
                <InputText id="refCli" v-model="refCli" :disabled="!editable && !invoiceEditingMode"/>
              </div>
              <div class="field mb-4 col-12 md:col-12">
                <label for="invoiceAddress">Adresse de facturation</label>
                <Textarea id="invoiceAddress" v-model="invoiceAddress"  :disabled="!editable && !invoiceEditingMode" rows="5" auto-resize/>
              </div>
              <div class="flex gap-2">
                <Button v-if="isClientCraExist" type="button" size="small" class="p-button-rounded p-button-sm" aria:haspopup="true"
                        aria-controls="overlay_panel" label="Compte rendu d'activité client" @click="openClientCRA"/>
                <Button v-if="!!mission" type="button" class="p-button-rounded p-button-sm" aria:haspopup="true"
                        aria-controls="overlay_panel" label="Fiche mission" @click="openMission"/>
                <Button v-if="!!invoiceDeclaration" type="button" class="p-button-rounded p-button-sm" aria:haspopup="true"
                        aria-controls="overlay_panel" label="Decl. Fac." @click="openIndependentCRA"/>
              </div>
            </div>
            </div>
        </div>
        <div class="mt-4">
          <OuterCardTitle value="Contenu de la facture"/>
          <div class="surface-card p-4 shadow-9 surface-round">
          <DataTable ref="dt" :value="servicesToBill" selection-mode="single" dataKey="id" rowHover
                     scrollable scroll-height="500px" @rowSelect="(event) => showPopupService(event.data)">
            <template #header>
              <div class="flex flex justify-content-end align-items-center">
                <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 450px" :breakpoints="{'960px': '75vw'}">
                  <DataTable ref="dt" :value="servicesAddable" :paginator="true" class="p-datatable-customers" :rows="constants.defaultRowNumber" @rowSelect="onServiceSelect" selection-mode="single"
                             dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" v-model:selection="selectedService"
                             paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
                             :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.services')})"
                             responsiveLayout="scroll">
                    <template #header>
                      <div class="p-d-flex flex">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
                      </span>
                      </div>
                    </template>
                    <template #empty>
                      {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
                    </template>
                    <template #loading>
                      {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
                    </template>
                    <Column field="reference" :header="$t('reference').toUpperCase()" :sortable="true" filterField="reference" sortField="reference" :showFilterMatchModes="false">
                    </Column>
                    <Column field="label" :header="$t('service').toUpperCase()" :sortable="true" filterField="label" sortField="label" :showFilterMatchModes="false">
                    </Column>
                  </DataTable>
                </OverlayPanel>
                <Checkbox v-if="editable && totalTTC > 0" v-model="mergeLines" binary class="mr-2"
                          @update:modelValue="mergeLinesChange"/>
                <Label v-if="editable && totalTTC > 0" class="mr-2">Fusionner les lignes de la facture</Label>
                <Button v-if="editable && totalTTC > 0" type="button" icon="pi pi-plus"
                        class="p-button-rounded p-button-sm" aria:haspopup="true"
                        aria-controls="overlay_panel" label="Ajouter un service" @click="toggle"/>
              </div>
            </template>
            <template #empty>
              {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
            </template>
            <template #loading>
              {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
            </template>
            <Column header="Numéro">
              <template #body="{index}">
                {{ index + 1 }}
              </template>
            </Column>
            <Column field="label" header="Désignation"/>
            <Column field="qty" header="Quantité"/>
            <Column header="Prix Unit.">
              <template #body="{data}">
                {{ getEuroFormatFromEuro(data.PU, false) }}
              </template>
            </Column>
            <Column header="Montant">
              <template #body="{data}">
                {{ getEuroFormatFromEuro(data.tot, false) }}
              </template>
            </Column>
            <Column header="Actions">
              <template #body="{data}">
                <div v-if="editable && totalTTC > 0" class="relative flex align-items-center"
                     style="left: 10px; width: 0;" @click.stop="removeService(data)">
                  <i class="pi pi-trash" style="font-size: 1.6rem;color: red"></i>
                </div>
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column footer="Total HT" :colspan="4" footerStyle="text-align:right" />
                <Column :footer="getEuroFormatFromEuro(totalHT, false)" :colspan="2"/>
              </Row>
              <Row>
                <Column :footer="'TVA '+ getPercentFormat(tauxTVA)" :colspan="4" footerStyle="text-align:right" />
                <Column :footer="getEuroFormatFromEuro(TVA, false)" :colspan="2"/>
              </Row>
              <Row>
                <Column footer="TOTAL" :colspan="4" footerStyle="text-align:right" />
                <Column :footer="getEuroFormatFromEuro(totalTTC, true)" :colspan="2"/>
              </Row>
            </ColumnGroup>
          </DataTable>
          </div>
        </div>
        <div class="flex justify-content-end mt-5">
          <Button v-if="servicesToBill.length > 0 && validable && invoice_number && !invoiceEditingMode" label="Modifier" class="p-button-rounded" @click="editInvoice"></Button>
          <Button v-if="servicesToBill.length > 0 && !invoice_number || invoiceEditingMode" label="Enregistrer" class="p-button-rounded" @click="save"></Button>
          <Button v-if="servicesToBill.length > 0 && validable && invoice_number" :disabled="invoiceEditingMode" label="Envoyer la facture" class="p-button-rounded ml-3" @click="openSendMailDialog"></Button>
          <Button v-if="servicesToBill.length > 0 && validable && invoice_number && totalTTC > 0" :disabled="invoiceEditingMode" label="Créer un avoir" @click="creerAvoir" class="p-button-rounded ml-3"></Button>
          <Button v-if="servicesToBill.length > 0 && validable && !invoice_number" label="Valider" class="p-button-rounded ml-3" @click="validate"></Button>
        </div>
      </div>
      <div style="width: 47%" class="mt-2">
        <OuterCardTitle value="Aperçu de la facture"/>
        <embed id="embededPdf" v-if="pdf1 && !pdfFile" :src="pdf1 + '#toolbar=0&navpanes=0&scrollbar=0'" width="100%" :height="computeA4OfPdf()"/>
        <embed id="embededPdf" v-else-if="pdfFile" :src="pdfFile + '#toolbar=0&navpanes=0&scrollbar=0'"  width="100%" :height="computeA4OfPdf()"/>
      </div>

      <Dialog v-model:visible="showDialogMission" :draggable="false" :style="{width: '70%'}" :modal="true"
              dismissable-mask class="p-fluid" @hide="showDialogMission = false" @close="showDialogMission = false">
        <template #header>
          <h3 class="mb-2">Fiche Mission</h3>
        </template>
        <fiche-mission
          ref="mission"
          :mission="mission"
          :editable="false"
          :independentId="independent?.id"
        />
      </Dialog>

      <Dialog v-if="invoiceDeclaration" v-model:visible="showDialogInvoiceDeclaration" :draggable="false" :style="{width: '70%'}" :modal="true"
              dismissable-mask class="p-fluid" @hide="showDialogInvoiceDeclaration = false" @close="showDialogInvoiceDeclaration = false">
        <template #header>
          <h3 class="mb-2">Declaration de facturation</h3>
        </template>
        <declare-facture
            :independent-id-forced="independent?.id"
            :month-forced="invoiceDeclarationMonthForced"
            :year-forced="invoiceDeclarationYearForced"
            :editable="false"
        />
      </Dialog>

      <Dialog @hide="resetServices()" :draggable="false" dismissable-mask v-model:visible="serviceDialog" :style="{width: '1000px'}" :modal="true" :show-header="true">
        <creation-service :service="serviceEdit" @change="updateServicesToBill"></creation-service>
      </Dialog>

      <dialog-n-d-f-admin :key="keyDialog" :dialog="dialogNdf" :ndf="selectedNdf" @close="dialogNdf = false; resetServices()"></dialog-n-d-f-admin>

      <dialog-prestation v-if="selectedService" :key="keyDialog" :dialog="dialogPrestation" :service="selectedService" @close="dialogPrestation = false; resetServices()"></dialog-prestation>

      <Dialog v-model:visible="sendMailDialog" :style="{width: '1000px'}" :modal="true" :show-header="true" :draggable="false" >
        <mail-dialog @close="sendMailDialog = false" :invoice="existingInvoice"></mail-dialog>
      </Dialog>

      <dialog-creer-avoir :invoice-id="existingInvoice?.id" :currency="currency" :dialog="avoirDialog"
                          :services-to-bill="servicesToBill" :conversion_rate="conversion_rate" :TVA="TVA"
                          :total-h-t="totalHT" :total-t-t-c="totalTTC" :taux-t-v-a="tauxTVA" :key="keyDialog"
                          @close="avoirDialog = false" @init="init"/>

      <dialog-file-preview :is-active="dialogFilePreview" :file-id="fileId" dialog-title="CRA Client" @close-dialog="closeDialogFilePreview"/>
    </div>
  </div>
</template>

<script>
import PdfGenerator from '@/utils/PdfGenerator'
import { getEuroFormatFromCurrency, getEuroFormatFromEuro, getPercentFormat } from '@/utils/Money'
import {mapGetters, mapState} from 'vuex'
import _, { debounce } from 'lodash'
import CurrencyList from 'currency-list'
import CreationService from '@/views/Pages/ADV/CreationService'
import MailDialog from "@/views/Pages/ADV/MailDialog";
import DialogNDFAdmin from "@/components/NDF/DialogNDFAdmin";
import DialogPrestation from "@/components/DialogPrestation";
import DialogCreerAvoir from "@/components/DialogCreerAvoir";
import {getBase64FromDownloadedFile} from "@/utils/Misc";
import DialogCrudBankAccount from "@/components/Invoice/DialogCrudBankAccount";
import Alert from "@/utils/Alert";
import DialogFilePreview from "@/components/DialogFilePreview.vue";
import DeclareFacture from "@/views/Pages/Porte/DeclareFacture.vue";
import OuterCardTitle from "@/components/OuterCardTitle";

export default {
  name: "Invoice",
  components: {
    OuterCardTitle,
    DeclareFacture,
    DialogFilePreview,
    DialogCrudBankAccount, DialogCreerAvoir, DialogPrestation, DialogNDFAdmin, MailDialog, CreationService },
  data () {
    return {
      keyDialog: 0,
      dialogNdf: false,
      selectedNdf: null,
      pdfGenerator: null,
      editable: true,
      validable: true,
      dialogPrestation: false,
      savedInvoice: {},
      serviceDialog: false,
      avoirDialog: false,
      sendMailDialog: false,
      currencyToMoveFirstCode: ['EUR', 'USD', 'GBP', 'CHF'],
      pdf1: null,
      filters: {
        'global': {value: null, matchMode: null},
      },
      invoiceDate: '',
      vatLiquidation: false,
      servicesToBill: [],
      totalHT: 0,
      totalTTC: 0,
      TVA: 0,
      tauxTVA: 0.2,
      invoiceAddress: '',
      buyerFactorCode: '',
      bank: undefined,
      settlementPeriod: null,
      selectedService: null,
      conversion_rate: 1,
      currencyList: [],
      currency: 'EUR',
      dialogBankAccount: false,
      accounts: [{ label: 'Paramétrage', items: [] }, { label: 'Comptes client', items: [] }],
      taxes: [
        {
          label: '0.0%',
          value: 0.0,
        },
        {
          label: '5.5%',
          value: 0.055,
        },
        {
          label: '20%',
          value: 0.2,
        }
      ],
      tvaIntra: '',
      numAcheteur: '',
      refCli: '',
      serviceEdit: undefined,
      typeTva: 'Sur les encaissements',
      reglement: 'par virement',
      invoice_number: undefined,
      servicesBackup: [],
      servicesToRemove: [],
      invoiceEditingMode: false,
      pdfFile: '',
      initializing: true,
      lastIndependentInvoice: null,
      mergeLines: false,

      showDialogMission: false,

      showDialogInvoiceDeclaration: false,
      invoiceDeclarationMonthForced: null,
      invoiceDeclarationYearForced: null,

      dialogFilePreview: false,
      fileId: null
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      bankAccounts: state => state.bankaccount.bankaccounts,
      settings: state => state.misc.settings
    }),
    ...mapGetters({
      services: 'service/getServices',
      independent: 'independent/getIndependent',
      mission: 'mission/getMission',
      existingInvoice: 'invoice/getInvoice'
    }),
    servicesAddable () {
      return this.services.filter((s) => {
        return s.independent.id === this.independent.id && s.mission.id === this.mission.id && s.status === this.constants.SERVICE_STATUS.TO_INVOICE && !s.selected
      })
    },
    invoiceDeclaration () {
      return this.getClientCraService()
    },
    isClientCraExist () {
      return this.invoiceDeclaration !== undefined && !!this.invoiceDeclaration?.service?.source[0].item?.craFile
    }
  },
  watch: {
    mission () {
      this.updateMission()
    },
    invoiceDate (value, oldValue) {
      if (oldValue === '' && this.settlementPeriod) return
      this.initSettlementPeriod()
      this.debouncedUpdate()
    },
    tauxTVA () {
      this.computeTotals()
      this.debouncedUpdate()
    },
    bank () {
      this.debouncedUpdate()
    },
    tvaIntra () {
      this.debouncedUpdate()
    },
    vatLiquidation () {
      this.debouncedUpdate()
    },
    currency () {
      this.debouncedUpdate()
    },
    conversion_rate () {
      this.debouncedUpdate()
    },
    numAcheteur () {
      this.debouncedUpdate()
    },
    refCli () {
      this.debouncedUpdate()
    },
    existingInvoice () {
      let i = this.formatInvoice()
      if (!this.invoiceEditingMode) this.validable = _.isEqual(this.savedInvoice, i) && this.existingInvoice
    },
    invoiceAddress () {
      this.debouncedUpdate()
    }
  },
  beforeUnmount() {
    this.debouncedUpdate.cancel()
  },
  async created () {
    if (this.$route.params.id) {
      await this.$store.dispatch('invoice/getOneInvoice', { id: this.$route.params.id })
      await this.$store.dispatch('independent/getOneIndependent', this.existingInvoice.independent.id)
      await this.$store.dispatch('mission/getOneMission', this.existingInvoice.mission.id)
    }
    if (!this.existingInvoice?.bank) {
      this.lastIndependentInvoice = await this.$store.dispatch('invoice/getLastInvoiceOfIndependentByMission', {
        independentId: this.independent.id,
        missionId: this.mission.id,
      })
    }
    this.initializing = false
    if (this.mission == undefined) {
      this.$router.push({ name: 'Services' }).then(() => {
        this.$router.go(0)
      })
    }
    else await this.init()
  },
  methods: {
    initSettlementPeriod () {
      this.settlementPeriod = this.invoiceDate ? new Date(this.invoiceDate) : new Date()
      if (this.mission.jsonCost.settlementPeriod.unit === this.constants.SETTLEMENT_PERIOD_TYPES.DAYS) {
        this.settlementPeriod.setDate(this.settlementPeriod.getDate() + parseInt(this.mission.jsonCost.settlementPeriod.value))
      } else if (this.mission.jsonCost.settlementPeriod.unit === this.constants.SETTLEMENT_PERIOD_TYPES.DAYS_END_OF_MONTH) {
        this.settlementPeriod.setDate(this.settlementPeriod.getDate() + parseInt(this.mission.jsonCost.settlementPeriod.value))
        this.settlementPeriod = new Date(this.settlementPeriod.getFullYear(), this.settlementPeriod.getMonth() + 1, 0)
      }
    },
    getClientCraService () {
      let clientCraServiceToBill = undefined
      if (!this.servicesToBill || this.servicesToBill?.length === 0) return clientCraServiceToBill
      clientCraServiceToBill = this.servicesToBill.find(serviceToBill =>
          serviceToBill?.service?.source && serviceToBill?.service?.source[0]?.collection === 'DeclarationFacturation'
      )
      if (clientCraServiceToBill === undefined) return clientCraServiceToBill
      return clientCraServiceToBill
    },
    async openClientCRA () {
      // todo: gonna take the first service with an invoice declaration assuming that there no other ones, check in the invoice the todo
      const clientCraServiceToBill = this.getClientCraService()
      if (!clientCraServiceToBill) {
        Alert.errorMessage(this, 'craClientNotAvailable')
        return
      }
      this.fileId = clientCraServiceToBill?.service?.source[0].item?.craFile
      this.dialogFilePreview= true
    },
    openMission () {
      this.showDialogMission = true
    },
    openIndependentCRA () {
      const clientCraServiceToBill = this.getClientCraService()
      if (!clientCraServiceToBill) {
        Alert.errorMessage(this, 'craClientNotAvailable')
        return
      }
      this.invoiceDeclarationMonthForced = clientCraServiceToBill.service.source[0].item.month
      this.invoiceDeclarationYearForced = clientCraServiceToBill.service.source[0].item.year
      this.showDialogInvoiceDeclaration = true
    },
    closeDialogFilePreview () {
      this.fileId = null
      this.dialogFilePreview= false
    },
    mergeLinesChange () {
      this.debouncedUpdate()
    },
    editInvoice () {
      this.invoiceEditingMode = true
    },
    resetServices () { // services broken after "voir fiche mission" ??
      this.$store.commit('service/SET_SERVICES', this.servicesBackup)
    },
    async addBankAccount (bankAccount) {
      this.$store.state.misc.loading = true
      const result = await this.$store.dispatch('bankaccount/createBankaccount', { ...bankAccount, type: 'client', client: this.mission.clientContract.id })
      this.accounts[1].items.push(result)
      this.bank = result
      this.dialogBankAccount = false
      this.$store.state.misc.loading = false
    },
    async init () {
      this.accounts[0].items = await this.$store.dispatch('bankaccount/getAllSettingsBankaccounts')
      this.accounts[1].items = await this.$store.dispatch('bankaccount/getAllBankaccountsByClient', this.mission?.clientContract?.id)
      const bankRegroup = [...this.accounts[0].items, ...this.accounts[1].items]
      this.bank = bankRegroup.find((bankItem) => bankItem.id === this.lastIndependentInvoice?.bank?.id) ?? this.accounts[0].items[0]
      this.debouncedUpdate = debounce(() => {
        if (this.pdfFile) return
        this.updatePdf()
        let i = this.formatInvoice()
        if (!this.invoiceEditingMode) this.validable = _.isEqual(this.savedInvoice, i) && this.existingInvoice
      }, 500)

      this.setupCurrency()

      if (this.mission) {
        this.updateMission()
      }

      this.invoiceAddress = this.mission?.clientContract?.invoiceAddress
      this.buyerFactorCode = this.mission?.clientContract?.buyerFactorCode
      if (!this.mission?.clientContract?.tva || this.taxes.find(taxe => taxe.value === this.mission?.clientContract?.tva) === undefined) {
        this.tauxTVA = 0.0
      }

      if (this.existingInvoice) {
        this.editable = this.existingInvoice.status === this.constants.INVOICE_STATUS.DRAFT
        this.existingInvoice.services.forEach((s) => {
          s.selected = true
        })
        this.$store.commit('service/SET_SERVICES', this.existingInvoice.services)

        this.settlementPeriod = this.existingInvoice.settlementPeriod
        this.invoiceDate = this.existingInvoice.date
        this.vatLiquidation = this.existingInvoice.vatLiquidation
        this.tvaIntra = this.existingInvoice.tva_i
        this.numAcheteur = this.existingInvoice.buyer_number
        this.tauxTVA = this.existingInvoice.tva
        this.bank = this.accounts[0].items.find(account => account.id === this.existingInvoice.bank.id)?? this.accounts[1].items.find(account => account.id === this.existingInvoice.bank.id)
        if (this.existingInvoice.currency) {
          this.currency = this.currencyList.find((c) => c.code === this.existingInvoice.currency)
        }
        this.refCli = this.existingInvoice.client_reference
        this.invoice_number = this.existingInvoice.invoice_number

        if (this.existingInvoice.pdf) {
          await this.loadPdfFile()
        }
      }

      if (!this.bank || !this.existingInvoice) {
        this.bank = bankRegroup.find((bankItem) => bankItem.id === this.mission?.clientContract?.bank?.id) ?? this.accounts[0].items[0]
      }

      this.updateServicesToBill()

      if (!this.existingInvoice) {
        const latestService = this.servicesToBill.sort((a, b) => new Date(b.year, b.month) - new Date(a.year, a.month))[0]?.service
        const latestDate = new Date(latestService.year, latestService.month)
        latestDate.setMonth(latestDate.getMonth() + 1, 0)
        this.invoiceDate = latestDate
      }

      this.savedInvoice = this.formatInvoice()
      this.validable = true
      this.$store.state.misc.loading = false
    },
    async loadPdfFile () {
      this.$store.state.misc.loading = true
      const result = await this.$store.dispatch('file/downloadFile', {id: this.existingInvoice.pdf})
      const file = new Blob([new Uint8Array(result.data.data)])
      const reader = new FileReader()
      reader.onload = () => {
        this.pdfFile = reader.result.replace('application/octet-stream', 'application/pdf')
        this.$store.state.misc.loading = false
      }
      reader.readAsDataURL(file)
    },
    openSendMailDialog () {
      const service = this.existingInvoice.services.find(s => s.source && s.source.length && s.source[0].collection === 'DeclarationFacturation' && s.source[0].item.craFile == undefined)
      if (service) {
          this.$confirm.require({
            header: 'CRA manquant',
            message: `Le CRA est manquant pour le service "${service.label}". Continuer l'envoi ?`,
            acceptLabel: 'Continuer',
            rejectLabel: 'Annuler',
            accept: () => {
              this.sendMailDialog = true
              this.$confirm.close()
            },
          })
      } else {
        this.sendMailDialog = true
      }
    },
    formatInvoice () {
      if (this.initializing) return
      return JSON.parse(JSON.stringify({
        independent: this.independent.id,
        mission: this.mission.id,
        invoiceDate: this.invoiceDate,
        bank: this.bank,
        iban: this.bank?.iban,
        rib: this.bank?.rib,
        tauxTVA: this.tauxTVA,
        tvaIntra: this.tvaIntra,
        currency: this.currency,
        vatLiquidation: this.vatLiquidation,
        conversion_rate: this.conversion_rate,
        numAcheteur: this.numAcheteur,
        refCli: this.refCli,
        services: this.services
      }))
    },
    openServiceDialog (service) {
      this.serviceEdit = service
      this.serviceDialog = true
    },
    closeServiceDialog () {
      this.serviceEdit = null
      this.serviceDialog = false
    },
    setupCurrency () {
      this.currencyList = []
      const list = CurrencyList.getAll('fr_FR')
      for (let curCode in list) {
        let cur = list[curCode]
        this.currencyList.push({
          code: cur.code,
          name: cur.name,
          symbol: cur.symbol,
        })
      }
      let currencyToMoveFirst = []
      this.currencyList.forEach((c) => {
        let index = this.currencyToMoveFirstCode.indexOf(c.code)
        if (index !== -1) {
          currencyToMoveFirst[index] = c
        }
      })
      this.currency = currencyToMoveFirst[0] // EURO
      currencyToMoveFirst.reverse().forEach((c) => {
        this.currencyList.splice(this.currencyList.indexOf(c), 1)
        this.currencyList.unshift(c)
      })
    },
    updateMission () {
      if (!this.existingInvoice) {
        if (!this.settlementPeriod) {
          this.initSettlementPeriod()
        }
        this.tvaIntra = this.mission.clientContract.tva_i
        this.numAcheteur = this.mission.clientContract.buyerFactorCode
        this.tauxTVA = this.mission.clientContract.tva
        this.vatLiquidation = this.mission.clientContract.vatLiquidation
        if (this.mission.clientContract.currency) {
          this.currency = this.currencyList.find((c) => c.code === this.mission.clientContract.currency)
        }
        this.refCli = this.mission.jsonClientFinal.client_reference
      }
    },
    updateServicesToBill () {
      this.servicesToBill = []
      let servicesToBill = this.services.filter((s) => s.selected)
      this.servicesBackup = JSON.parse(JSON.stringify(servicesToBill))
      servicesToBill.forEach((s) => {
        this.servicesToBill.push({
          service: s,
          id: s.id,
          label: s.label,
          qty: s.quantity,
          PU: s.montantHT,
          tot: s.price
        })
      })
      this.computeTotals()
      this.debouncedUpdate()
      this.serviceDialog = false
    },
    async getSettingCompanyLogo (callback) {
      const companyLogo = await this.$store.dispatch('file/downloadFile', { id: this.settings.Entreprise.logo})
      return getBase64FromDownloadedFile(companyLogo, (result) => {
        callback(result)
      })
    },
    async updatePdf () {
      await this.getSettingCompanyLogo(async (result) => {
        let invoiceNamingTemplate
        if (this.totalTTC > 0) {
          invoiceNamingTemplate = this.settings.ADV.invoiceNaming.template.join('')
        } else {
          invoiceNamingTemplate = this.settings.ADV.invoiceNaming.avoir.join('')
        }
        const invoiceNumberTemplated = this.invoice_number ? invoiceNamingTemplate.replace(/YYYY/g, (new Date()).getFullYear()).replace(/MM/g, ("0" + ((new Date()).getMonth() + 1)).slice(-2)).replace(/NAME/g, this.independent.last_name.toUpperCase()).replace(/REF/g, this.invoice_number).replace(/CLI/g, this.mission.clientContract.name.toUpperCase()).replace(/\s/g, '') : ''
        const footerTemplate = await this.$store.dispatch('misc/getDefaultFooterTemplate')
        const invoicePaymentsRequirements = await this.$store.dispatch('misc/getFormattedTemplate', this.settings.ADV.invoicePaymentRequirements)
        this.pdfGenerator = new PdfGenerator({
          settings: this.settings,
          companyLogo: result,
          services: this.servicesToBill.map(s => ({
            ...s,
            PU: this.currency.code !== 'EUR' ? s.PU * this.conversion_rate : s.PU,
            tot: this.currency.code !== 'EUR' ? s.tot * this.conversion_rate : s.tot
          })),
          totalHT: this.totalHT * (this.currency.code !== 'EUR' ? this.conversion_rate : 1),
          totalTTC: this.totalTTC * (this.currency.code !== 'EUR' ? this.conversion_rate : 1),
          TVA: this.TVA * (this.currency.code !== 'EUR' ? this.conversion_rate : 1),
          tauxTVA: this.tauxTVA,
          client: this.mission.clientContract,
          tva_i: this.tvaIntra,
          dateI: this.invoiceDate,
          settlementPeriod: this.settlementPeriod,
          settlementPeriodDelay: this.mission.jsonCost.settlementPeriod.unit === this.constants.SETTLEMENT_PERIOD_TYPES.ON_RECEPTION ? this.constants.SETTLEMENT_PERIOD_TYPES.ON_RECEPTION : this.mission.jsonCost.settlementPeriod.value + ' ' + this.mission.jsonCost.settlementPeriod.unit?.toLowerCase(),
          bankAccount: this.bank?.libelle,
          iban: this.bank?.iban,
          bic: this.bank?.bic,
          IndepFullName: this.independent.first_name + ' ' + this.independent.last_name,
          refCli: this.refCli,
          invoiceAddress: this.invoiceAddress,
          vatLiquidation: this.vatLiquidation,
          currency: this.currency.code,
          typeTva: this.typeTva,
          reglement: this.reglement,
          invoiceNumber: this.invoice_number,
          invoiceNumberTemplated: invoiceNumberTemplated,
          mergeLines: this.mergeLines,
          footerTemplate,
          invoicePaymentsRequirements
        }, 'invoice')
        await this.pdfGenerator.getPdf((dataUrl) => {
          this.pdf1 = dataUrl
          this.showSimulation = true
        })
      })
    },
    getEuroFormatFromEuro (price, withUnit) {
      return getEuroFormatFromEuro(price, withUnit)
    },
    getEuroFormatFromCurrency (price, currency) {
      return getEuroFormatFromCurrency(price, currency)
    },
    getPercentFormat (value) {
      return getPercentFormat(value)
    },
    computeTotals () {
      this.totalHT = 0
      this.servicesToBill.forEach((service) => {
        this.totalHT = (this.totalHT * 100 + service.tot * 100) / 100
      })
      this.TVA = this.totalHT * this.tauxTVA
      this.totalTTC = (this.totalHT * 100 + this.TVA * 100) / 100
    },
    toggle (event) {
      this.$refs.op.toggle(event);
    },
    onServiceSelect(event) {
      this.$refs.op.hide();
      event.data.selected = true
      this.updateServicesToBill()
    },
    async save () {
      this.$store.state.misc.loading = true
      let bank = this.bank
      let invoice = {
        independent: this.independent.id,
        mission: this.mission.id,
        date: this.invoiceDate,
        status: this.constants.INVOICE_STATUS.DRAFT,
        ttc: this.totalTTC,
        services: this.servicesToBill.map((s) => {return {id: s.id, status: this.constants.SERVICE_STATUS.INVOICING}}),
        bank: bank,
        paymentDelay: this.mission.jsonCost.settlementPeriod.unit === this.constants.SETTLEMENT_PERIOD_TYPES.ON_RECEPTION ? this.constants.SETTLEMENT_PERIOD_TYPES.ON_RECEPTION : this.mission.jsonCost.settlementPeriod.value + ' ' + this.mission.jsonCost.settlementPeriod.unit?.toLowerCase(),
        settlementPeriod: this.settlementPeriod,
        tva: this.tauxTVA,
        tva_i: this.tvaIntra,
        currency: 'EUR',
        buyer_number: this.numAcheteur,
        client_reference: this.refCli,
        clientId: this.mission.clientContract.id,
        vatLiquidation: this.vatLiquidation,
        mergeLines: this.mergeLines
      }
      if (!this.mission?.clientContract?.invoiceAddress || this.mission?.clientContract?.invoiceAddress !== this.invoiceAddress) {
        invoice.invoiceAddress = this.invoiceAddress
      }
      if (this.currency.code !== 'EUR') {
        invoice.conversion_rate = this.conversion_rate
      }
      if (this.existingInvoice) {
        invoice.id = this.existingInvoice.id
        for (const service of this.servicesToRemove) {
          if (!invoice.services.find(s => s.id === service.id)) {
            await this.$store.dispatch('service/saveService', { id: service.id, status: this.constants.SERVICE_STATUS.TO_INVOICE })
          }
        }
      }
      if (this.invoiceEditingMode) {
        invoice.status = this.constants.INVOICE_STATUS.INVOICED
        if (invoice.bank?.client?.id) {
          invoice.bank.client = invoice.bank?.client?.id
        }
      }
      this.$store.dispatch('invoice/saveInvoice', invoice).then(() => {
        this.$toast.add({severity:'success', summary:'Succès', detail:'Facture enregistrée', life: 3000});
        this.savedInvoice = this.formatInvoice()
        if (this.pdfFile) {
          this.loadPdfFile()
        } else {
          this.debouncedUpdate()
        }
        this.invoiceEditingMode = false
        this.$store.state.misc.loading = false
      }).catch(() => {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Une erreur est survenue', life: 3000});
        this.$store.state.misc.loading = false
      })
    },
    async validate () {
      this.$store.state.misc.loading = true
      this.$store.dispatch('invoice/validateInvoice', { id: this.existingInvoice.id, mergeLines: this.mergeLines }).then(() => {
        this.$toast.add({severity:'success', summary:'Succès', detail:'Facture validée', life: 3000});
        this.savedInvoice = this.formatInvoice()
        this.editable = false
        this.invoice_number = this.existingInvoice.invoice_number
        this.debouncedUpdate()
        this.loadPdfFile()
        this.$store.state.misc.loading = false
      }).catch(() => {
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Une erreur est survenue', life: 3000});
        this.$store.state.misc.loading = false
      })
    },
    creerAvoir () {
      this.keyDialog++
      this.avoirDialog = true
      // this.$store.state.misc.loading = true
      //
      // this.$store.dispatch('invoice/creerAvoir', { id: this.existingInvoice.id }).then(() => {
      //   this.$toast.add({severity:'success', summary:'Succès', detail:'Avoir créé', life: 3000});
      //   this.init()
      // })
      //
      // this.$store.state.misc.loading = false
    },
    commonFilter (field) {
      let array = this.services.map((s) => {
        let fields = field.split('.')
        let v = s
        fields.forEach((f) => {
          v = v[f]
        })
        return v
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item || Math.abs(fa.value - item) === 0)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    showPopupService (service) {
      let s = this.services.find((se) => se.id === service.id)
      if (s.source.length > 0) {
        if (s.source[0].collection === 'ndf') {
          this.selectedNdf = s.source[0].item
          this.selectedNdf.date = new Date(this.selectedNdf.date)
          this.selectedNdf.depenses.map(dep => {
            if (!dep.processed) {
              dep.date = new Date(dep.date)
              dep.ttc /= 100
              dep.ht /= 100
              dep.tva /= 100
            }
            dep.processed = true
          })
          this.keyDialog++
          this.dialogNdf = true
        } else if (s.source[0].collection === 'DeclarationFacturation') {
          this.selectedService = s
          this.keyDialog++
          this.dialogPrestation = true
        } else {
          this.openServiceDialog(s)
        }
      } else {
        this.openServiceDialog(s)
      }
    },
    computeA4OfPdf () {
      let element = document.querySelector('#embededPdf')
      if (element) {
        return element.getBoundingClientRect().width * Math.sqrt(2) + 'px'
      }
      return '1000px'
    },
    removeService (service) {
      if (this.servicesToBill.length <= 1) {
        this.$confirm.require({
          header: 'Supprimer la facture',
          message: 'Vous êtes sur le point de supprimer le dernier service de cette facture, elle sera alors supprimée.',
          acceptLabel: 'Confirmer',
          rejectLabel: 'Annuler',
          accept: async () => {
            this.$store.state.misc.loading = true
            if (this.existingInvoice) {
              await this.$store.dispatch('invoice/removeInvoice', this.existingInvoice.id)
            }
            this.$toast.add({severity:'success', summary:'Succès', detail:'Facture supprimée', life: 3000});
            this.$router.push({ name: 'Services' })
            this.$store.state.misc.loading = false
            this.$confirm.close()
          },
          reject: () => {
            this.$confirm.close()
          }
        })
      } else {
        service.service.selected = false
        this.servicesToRemove.push(service)
        this.updateServicesToBill()
      }
    },
    changeSettlementPeriod () {
      this.debouncedUpdate()
    }
  }
}
</script>

<style scoped>
th, td {
  border: solid black 1px;
}
.custom-border-top-1 {
  border-top: solid black 1px;
}
.custom-border-right-1 {
  border-right: solid black 1px;
  border-left: solid transparent 1px;
}
.custom-border-x-1 {
  border-right: solid black 1px;
  border-left: solid black 1px;
}
.custom-border-bottom-2 {
  border-bottom: solid black 2px;
}
.td-border-top {
  border-top: solid black 1px !important;
  border-left: solid transparent 1px;
  border-right: solid transparent 1px;
  border-bottom: solid transparent 1px;
}
.td-border-right {
  border-right: solid black 1px !important;
  border-left: solid transparent 1px;
  border-bottom: solid transparent 1px;
  border-top: solid transparent 1px;
}
.td-border-bottom {
  border-bottom: solid black 1px !important;
  border-left: solid transparent 1px;
  border-right: solid transparent 1px;
  border-top: solid transparent 1px;
}
.td-border-right-only {
  border-right: solid black 1px !important;
  border-left: solid transparent 1px;
  border-bottom: solid transparent 1px;
  border-top: solid transparent 1px;
}
.td-no-border {
  border-color: transparent;
}
table {
  border-collapse: collapse;
  width: 42vw;
}
tr {
  display: flex;

}
th {
  background-color: #DDDDDD;
  font-weight: bold;
  font-size: medium;
  color: black;
  height: 100%;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.td-total {
  background-color: #DDDDDD;
  font-size: 14px;
}
td {
  font-size: 13px;
  color: black;
  height: 100%;
  align-items: center;
  width: 100%;
  display: flex;
}
tr {
  height: 1.85vw;
}
</style>
<style scoped>


.p-card {
  /*width: 50rem;*/
  border-radius: 20px;
}

.left-cards {
  width: 40%;
}

.right-cards {
  width: 60%;
}

.p-card-content > div {
  gap: 0;
}

/*.p-inputtext {*/
/*  width: 15rem;*/
/*}*/

.dropdown-units {
  width: auto;
}

.inputtext-short {
  width: 2rem;
}

.inputtext-medium {
  width: 6rem;
}

</style>
