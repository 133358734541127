<template>
  <Dialog :draggable="false" v-model:visible="dialogActive" class="p-4 documentDialog" :modal="true" content-class="dialog-content" :show-header="false" close-on-escape dismissableMask>
    <div class="mt-2">
      <h3 class="mb-2">Compte bancaire client</h3>
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid p-2">
        <div>
          <label for="libelle" :class="{'p-error':v$.libelle.$invalid && submitted}" class="block text-900 font-medium mb-2"> Libellé *</label>
          <InputText id="libelle" v-model="v$.libelle.$model" type="text" :class="{'p-invalid':v$.libelle.$invalid && submitted}" class="w-full mb-3 p-3" />
          <div v-if="v$.libelle.$error && submitted" class="mb-2">
          <span id="libelle-error" v-for="(error, index) of v$.libelle.$errors" :key="index">
            <InlineMessage>{{error.$message}}</InlineMessage>
          </span>
          </div>
          <label for="iban" :class="{'p-error':v$.iban.$invalid && submitted}" class="block text-900 font-medium mb-2"> IBAN *</label>
          <InputText id="iban" v-model="v$.iban.$model" type="text" :class="{'p-invalid':v$.iban.$invalid && submitted}" class="w-full mb-3 p-3" />
          <div v-if="v$.iban.$error && submitted" class="mb-2">
          <span id="iban-error" v-for="(error, index) of v$.iban.$errors" :key="index">
            <InlineMessage>{{error.$message}}</InlineMessage>
          </span>
          </div>
          <label for="rib" :class="{'p-error':v$.rib.$invalid && submitted}" class="block text-900 font-medium mb-2"> RIB *</label>
          <InputText id="rib" v-model="v$.rib.$model" type="text" :class="{'p-invalid':v$.rib.$invalid && submitted}" class="w-full mb-3 p-3" />
          <div v-if="v$.rib.$error && submitted" class="mb-2">
          <span id="rib-error" v-for="(error, index) of v$.rib.$errors" :key="index">
            <InlineMessage>{{error.$message}}</InlineMessage>
          </span>
          </div>
          <label for="bic" :class="{'p-error':v$.bic.$invalid && submitted}" class="block text-900 font-medium mb-2"> BIC *</label>
          <InputText id="bic" v-model="v$.bic.$model" type="text" :class="{'p-invalid':v$.bic.$invalid && submitted}" class="w-full mb-3 p-3" />
          <div v-if="v$.bic.$error && submitted" class="mb-2">
          <span id="bic-error" v-for="(error, index) of v$.bic.$errors" :key="index">
            <InlineMessage>{{error.$message}}</InlineMessage>
          </span>
          </div>
        </div>

        <div class="flex flex-row-reverse mt-2">
          <div class="flex col-6 justify-content-between">
            <Button :label="$t('validate')" type="submit" class="w-full p-3"></Button>
          </div>
        </div>
      </form>
    </div>
  </Dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Alert from "@/utils/Alert";

export default {
  emits: ["closeDialog", "sendBankAccount"],
  props: ["isActive"],
  setup: () => ({ v$: useVuelidate() }),
  name: "DialogCrudBankAccount",
  watch: {
    isActive (value) {
      this.dialogActive = value
    }
  },
  data () {
    return {
      dialogActive: false,
      formValidated: false,

      libelle: '',
      iban: '',
      rib: '',
      bic: '',

      submitted: false
    }
  },
  validations() {
    return {
      libelle: {
        required: helpers.withMessage(this.$t('documentEmailSubjectRequired'), required),
      },
      iban: {
        required: helpers.withMessage(this.$t('documentEmailSubjectRequired'), required),
      },
      rib: {
        required: helpers.withMessage(this.$t('documentEmailSubjectRequired'), required),
      },
      bic: {
        required: helpers.withMessage(this.$t('documentEmailSubjectRequired'), required),
      },
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    async handleSubmit (isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      this.$emit('sendBankAccount', { libelle: this.libelle, iban: this.iban, rib: this.rib, bic: this.bic })
    },
  }
}
</script>

<style>
.documentDialog {
  width: 800px;
  background-color: white;
}
</style>
